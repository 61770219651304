import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/IndustryJqr.vue')
  },
  {
    path: '/industrybz',
    name: 'industrybz',
    component: () => import('@/views/IndustryBz.vue')
  },
  {
    path: '/industrydt',
    name: 'industrydt',
    component: () => import('@/views/IndustryDt.vue')
  },
  {
    path: '/industrylc',
    name: 'industrylc',
    component: () => import('@/views/IndustryLc.vue')
  },
  {
    path: '/industrylt',
    name: 'industrylt',
    component: () => import('@/views/IndustryLt.vue')
  },
  {
    path: '/industryzg',
    name: 'industryzg',
    component: () => import('@/views/IndustryZg.vue')
  },
  {
    path: '/benchmark',
    name: 'benchmark',
    component: () => import('@/views/Anli.vue')
  },
  {
    path: '/benchmarkdetail/:key',
    name: 'benchmarkdetail',
    component: () => import('@/views/AnliMsg.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('@/views/ContactUs.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 } // 将滚动位置设置为顶部
  },
  routes
})

export default router
