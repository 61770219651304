<template>
  <div class="example">
    <div class="title">标杆案例</div>
    <div class="content">
      <a-row type="flex" class="list" justify="center" :gutter=[12,4]>
        <a-col class="container" v-for="(i,key) in list" :sm="4"  :xs="12" :key="key">
          <div class="content-item" @click="goPage('benchmarkdetail/'+key)">
            <div class="image">
              <img style="width:100%" :src="i.image[0]"  alt="">
            </div>
            <div class="name">{{i.title}}</div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {example} from '@/utils/data'
export default {
  name: 'example',
  data () {
    return {
      list: example
    }
  },
  mounted () {
  },
  methods: {
    goPage(path) {
      this.$router.push(path).catch(() => true)
    }
  }
}
</script>
<style lang="less" scoped>
.example{
  height: 600px;
  padding-top: 100px;
  background: url('../../assets/home/example-bg.png') no-repeat;
  background-size: cover;
  .title{
    font-weight: bold;
    font-size: 40px;
    color: #333;
    line-height: 60px;
    margin-bottom: 32px;
  }
  .content{
    // width: 1200px;
    margin: 0 auto;
  }
  .list{
    margin: 0 auto !important;
  }
  .content-item{
    cursor: pointer;
    width: 293px;
    // width: 100%;
    height: 270px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px #D0D6DF;
    border-radius: 8px;
    margin-bottom: 12px;
    .image{
      height: 180px;
      // background: #ccc;
    }
    .name{
      text-align: left;
      padding-left: 21px;
      padding-top: 30px;
      font-size: 20px;
      color: #333;
    }
  }
}
@media (max-width:1536px){
  .container{
    width: 20%;
  }
  .content-item{
    width: 100% !important;
  }
}

@media (max-width:1200px) {
  .example{
    height: auto;
    padding: 20px 12px;
    .container{
      width: 50%;
    }
    .list{
      justify-content: flex-start;
    }
    .title{
      font-size: 20px;
      margin-bottom: 12px;
    }
    .content{
      width: 100%;
    }
    .content-item{
      width: 100%;
      height: 160px;
      .image{
        height: 90px;
      }
      .name{
        text-align: center;
        font-size: 12px;
        padding-left: 0;
      }
    }
  }
}
</style>