<template>
  <div class="foot">
    <div class="logos">
      <a href="http://www.emergen.cn/" target="_blank"><div class="f-logo1"></div></a>
      <div class="f-logo"  @click="goPage('/')"></div>
    </div>
    <div class="f-title">
      <a class="f-t-item" @click="goPage('/industry')">业务板块</a>
      <!-- <a class="f-t-item" @click="goPage('/benchmark')">标杆案例</a> -->
      <a class="f-t-item" @click="goPage('/about')">走进瀚普</a>
      <a class="f-t-item" @click="goPage('/contactus')">联系我们</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foot',
  methods: {
    goPage(path) {
      this.$router.push(path).catch(() => true)
    }
  }
}
</script>

<style lang="less" scoped>
.foot{
  height: 229px;
  padding-top: 49px;
  background: #f6f6f6;
  padding-top: 50px;
  .logos{
    margin-left: 122px;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    cursor: pointer;
    .f-logo{
      width: 81px;
      height: 55px;
      background: url('../../assets/logo.png') no-repeat;
      background-size: 100% 100%;
    }
    .f-logo1{
      width: 113px;
      height: 35px;
      background: url('../../assets/zclogo.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 50px;
    }
  }
  .f-title{
    padding-left: 145px;
    padding-top: 28px;
    text-align: left;
    .f-t-item{
      margin-right: 40px;
      color: #181818;
      font-size: 14px;
    }
  }

}
@media (max-width:1200px){
  .foot{
    height: auto;
    padding: 25px 0;
    .logos{
      margin-left: 22px;
      .f-logo{
        width: 40px;
        height: 28px;
      }
      .f-logo1{
        width: 60px;
        height: 20px;
        margin-right: 20px;
      }
    }
    .f-title{
      padding-left: 20px;
      padding-top: 10px;
      .f-t-item{
        margin-right: 40px;
        font-size: 14px;
      }
    }

  }
}
</style>