

const dataJson = {
  // 智慧料场
  zhlc: {
    id: 1,
    title: '大型设备无人化',
    content: [
      {
        title: '方案简介',
        textList: ['基于三元控制体系的智慧料场颠覆传统生产方式，全面实现智能化，大幅提高生产效率，减少50%以上的生产人员。结合不同现场工况最高可减少90%的颗粒物扬尘，杜绝地面水污染，改善生产作业环境']
      },
      {
        title: '创新效益',
        textList: [
          '① 降低人工成本：每班每人可同时监管2～3台大型设备，根据实际需要核减配员。',
          '② 节约电费（结合现场工况核算）：作业效率提升10%，每日缩减近2小时作业时长，全年节约电费可超过200万元。'
        ]
      }
    ],
    apply: '',
    image:[
      require('../assets/detail/1-1.png'),
      require('../assets/detail/1-2.png'),
      require('../assets/detail/1-3.png'),
    ]
  },
  // 智慧制粉和喷吹系统
  zfpc: {
    id: 2,
    title: '智慧制粉和喷吹系统',
    content: [
      {
        title: '方案简介',
        textList: ['高炉制粉喷煤系统是炼铁工艺流程的重要一环，高炉煤粉是冶炼过程中以煤代焦提供热量和还原剂，从而节约能源成本和焦炭资源的重要措施，实现高喷煤量将会大大降低铁前成本。']
      },
      {
        title: '创新效益',
        textList: [
          '① 改进工艺控制方式，提高磨煤机台时产能≥10%',
          '② 提高运行效率，节约用电量5%',
          '③ 降低燃料成本，节约氮气成本30%，并改善高炉生产工况，减少热损失；',
          '④ 错峰生产、合理优化峰谷用电，在谷电价满负荷生产，峰电价停机，置换原有20%的产能谷电价生产；',
          '⑤ 提高设备运行安全性，降低设备维护费用，降低岗位用工成本。'
        ]
      }
    ],
    apply: '河钢唐钢、河钢承钢',
    image:[
      require('../assets/detail/2-1.png'),
      require('../assets/detail/2-2.png'),
    ]
  },
  // 原燃料粒度智能识别
  ldsb: {
    id: 3,
    title: '原燃料粒度智能识别',
    content: [
      {
        title: '方案简介',
        textList: ['通过对传送带上焦炭、矿石、烧结矿等物料的数字图像采集分析，结合大数据专用模型算法，实时进行物料颗粒尺度的计算分析与分布统计，为高炉操作者快速、准确、连续地提供粒度信息数据。']
      },
      {
        title: '核心功能',
        textList: [
          '① 智能化实时输出原燃料粒度及其分布情况；',
          '② 根据用户要求进行粒级分布分析和趋势管理；',
          '③ 大块物料报警提示，防止物料堵塞高炉炉顶或料仓排料口；',
          '④ 全数字化，可以依据用户要求进行软件定制；',
          '⑤ 可以进行大数据分析，自动报警',
          '⑥ 图像数据的存储和分析。'
        ]
      }
    ],
    apply: '河钢唐钢',
    image:[
      require('../assets/detail/3-1.png'),
      require('../assets/detail/3-2.png'),
      require('../assets/detail/3-3.png'),
    ]
  },
  // 高炉铁水连续测温技术
  cwjs: {
    id: 4,
    title: '高炉铁水连续测温技术',
    content: [
      {
        title: '方案简介',
        textList: ['铁水温度是操作高炉的重要参数之一，应用远距离、非接触式、连续测量出铁时铁口处的铁流温度，可以更直接、更准确代表了炉缸物理热的状态和变化过程。']
      },
      {
        title: '核心功能',
        textList: [
          '① 双波段、比色式测温，高精度≤0.5%；',
          '② 红外测温，可穿透一定量的水汽、灰尘等；',
          '③ 非接触式、连续测温，长久使用无损耗；',
          '④ 测温目标大小变化、部分被遮挡、信号衰减等不影响精度；',
          '⑤ 集成吹风防尘降温防护系统，稳定可靠，可满足炉前严苛工况',
          '⑥ 集成远程瞄准调节系统，可在操作室随时调整测量位置；',
          '⑦ 具有远距离测量铁口，近距离测量铁沟铁流温度两种安装方式及防护结构。'
        ]
      }
    ],
    apply: '',
    image:[
      require('../assets/detail/4-1.png'),
      require('../assets/detail/4-2.png'),
    ]
  },
  // 铁水罐耐材侵蚀评估与管理系统
  qspg: {
    id: 5,
    title: '铁水罐耐材侵蚀评估与管理系统',
    content: [
      {
        title: '方案简介',
        textList: ['在尽量延长铁水罐使用时间的前提下，采用三元控制技术，及时在线发现损坏的铁水罐或趋势，杜绝安全隐患、降低成本，为生产安全和铁水罐管理带来巨大提升。']
      },
      {
        title: '核心功能',
        textList: [
          '① 铁水罐罐体红外显示和温度监测；',
          '② 铁水罐温度变化梯度趋势分析；',
          '③ 铁水罐耐材残厚评估；',
          '④ 铁水罐罐体状态分级评价与五级报警；',
          '⑤ 铁水罐罐号自动识别',
          '⑥ 使用次数自动提醒；',
          '⑦ 建立铁水罐档案数据库；',
          '⑧ 铁水罐全生命周期管理。'
        ]
      }
    ],
    apply: '',
    image:[
      require('../assets/detail/5-1.png'),
      require('../assets/detail/5-2.png'),
      require('../assets/detail/5-3.png'),
      require('../assets/detail/5-4.png'),
    ]
  },
  // 大型风机极致能效技术
  fjnx: {
    id: 6,
    title: '大型风机极致能效技术',
    content: [
      {
        title: '方案简介',
        textList: ['大型风机极致能效技术为钢企深度挖掘风机系统节能潜力，避免对风机系统反复式改造，深度挖潜是实现风机节能降耗30%的可行路径。所有的节能都以电表和实际效果作为验证的唯一依据，让节能真正为钢企创造价值。']
      },
      {
        title: '核心技术',
        textList: [
          '风机系统极致能效技术采用CFD流场分析、FEM有限元分析、三元控制等核心技术，可以量化每一部分改造的投资回报比和对最终节能效果的贡献率，包括但不限于风机、电机、除尘器、管道、三元控制技术等节能手段。'
        ]
      },
      {
        title: '创新效益',
        textList: [
          '某钢企炼铁厂矿槽除尘风机完成极致能效改造后节能效率34%。'
        ]
      }
    ],
    apply: '',
    image:[
      require('../assets/detail/6-1.png'),
      require('../assets/detail/6-2.png'),
      require('../assets/detail/6-3.png'),
    ]
  },
  // 智慧定重定尺系统
  dzdc: {
    id: 7,
    title: '智慧定重定尺系统',
    content: [
      {
        title: '产品介绍',
        textList: ['将连铸过程工艺参数与大数据智能分析技术深度融合，建立基于铸坯单位长度重量在线实时预判的连铸工艺大数据分析模型，辅以红外/激光定尺切割系统和钢坯在线称量系统，实现对连铸钢坯的精确切割和精准重量控制。']
      },
      {
        title: '核心技术',
        textList: [
          '① 定制化在线称重设备及精确称量技术',
          '② 基于激光/红外摄像技术的精确定尺切割技术',
          '③ 基于铸坯单位长度重量预判的连铸工艺大数据模型',
          '④ 基于多源异构数据融合技术的铸坯精确控制模型',
          '⑤ 可集成连铸红热钢坯高温贴标和实时物料跟踪系统，通过铸轧联动，实现钢坯利用最大化，提高成材率',
        ]
      },
      {
        title: '效益分析',
        textList: [
          '① 小方坯：目标重量±2‰氛围内，定重率≥90%',
          '② 大方坯、矩形坯、板坯：目标重量±2‰氛围内，定重率≥85%',
          '③ 轧钢成材率提升0.3%~1.0%，按该连铸线154万吨/年产能计算，年均降本300万以上。',
        ]
      }
    ],
    apply: '河钢唐钢、河钢宣钢、鞍山紫竹集团',
    image:[
      require('../assets/detail/7-1.png'),
      require('../assets/detail/7-2.png'),
      require('../assets/detail/7-3.png'),
    ]
  },
  // 精整线智能机器人集群作业
  jzx: {
    id: 8,
    title: '精整线智能机器人集群作业',
    content: [
      {
        title: '方案简介',
        textList: ['依托三元控制架构，打造基于“平台+控制器+智能装备”的智能化精整生产线。激发每个工位机器人的作业潜能，真正实现全域感知、实时分析、动态调度、精准执行，大大提升精整线的生产质量和作业效率。']
      },
      {
        title: '核心技术',
        textList: [
          '① 智昌独创三元控制架构（规划-调度-控制）',
          '② 工业机器人非标定制集成能力 ',
          '③ 柔性生产、自动排程及动态调度技术',
          '④ 精确的逐个物料实时跟踪技术',
          '⑤ 人机交互集控平台技术 ',
          '⑥ 基于数字孪生的模拟生产技术',
          '⑦ 群智自学习、自优化控制技术',
        ]
      },
      {
        title: '智能装备',
        textList: [
          '拆捆上料机器人、激光打码机器人、样棒库桁架机器人、修磨机器人、喷漆机器人、打捆机器人、贴标机器人、校秤桁架机器人、成品库区无人天车、直线度智能检测装置、倒棱质量智能检测装置等。'
        ]
      },
      {
        title: '创新效益',
        textList: [
          '每条产线每个生产班组从18人减至3人；整体产能提升16.7%；整体能耗成本降低10%；特钢棒材的质量稳定性得到极大提升，成品棒材的表面质量缺陷实现“零缺陷。'
        ]
      }
    ],
    apply: '河钢石钢、承德建龙',
    image:[
      require('../assets/detail/8-1.png'),
      require('../assets/detail/8-2.png'),
      require('../assets/detail/8-3.png'),
      require('../assets/detail/8-4.png'),
    ]
  },
  // 连铸机自动加保护渣机器人
  lzj: {
    id: 9,
    title: '连铸机自动加保护渣机器人',
    content: [
      {
        title: '方案简介',
        textList: ['以6轴工业机器人为平台，通过步进电机以及定量给定器，按预先设定的轨迹，将保护渣通过螺旋输送方式送到加渣导杆中，均匀散布到结晶器表面，保证加渣稳定性及渣层的均匀性，为连铸机的稳定可靠运行及连铸坯质量提升提供有效保障']
      },
      {
        title: '核心功能',
        textList: [
          '① 实现“勤加、薄加、均匀加”的工艺要求，不漏渣，无扬尘；',
          '② 采用螺旋输送可保证加渣的均匀性；',
          '③ 机器人悬挂安装，可避免跑钢事故导致机器人设备损坏；',
          '④ 采用二级料仓，解决了软管输渣对断面宽度限制；',
          '⑤ 机器人在线自动调节加渣面宽度及渣量分区。',
        ]
      },
    ],
    apply: '兴澄特钢',
    image:[
      require('../assets/detail/9-1.png'),
      require('../assets/detail/9-2.png'),
    ]
  },
  // 抓斗类无人行车
  zd: {
    id: 10,
    title: '抓斗类无人行车',
    content: [
      {
        title: '方案简介',
        textList: ['应用于抓渣、干煤棚、散装料、旋流井等抓取场景，实现行车全自动运行和多车协调联动作业、料种按区堆取、自动倒料和掺配、料仓自动上料和防堵料等功能，降低作业强度，改善作业环境，提高工作效率和安全性、提升库区信息化管理水平。']
      },
      {
        title: '核心功能',
        textList: [
          '① 采用精准定位、三维扫描、电子防摇、智能AI检测、智能抓斗控制等先进技术；',
          '② 系统可在振动、高温，腐蚀等恶劣环境下稳定运行；',
          '③ 四绳配合算法，确保钢丝绳时刻保持受力均匀；',
          '④ 具有作业地面实时监控及作业路径规划；',
          '⑤ 抓斗并斗算法功能，可优化作业效率；',
          '⑥ 具备多重保护，可有效预防通讯干扰等影响行车作业安全',
        ]
      },
    ],
    apply: '兴澄特钢',
    image:[
      require('../assets/detail/10-1.png'),
      require('../assets/detail/10-2.png'),
      require('../assets/detail/10-3.png'),
      require('../assets/detail/10-4.png'),
    ]
  },
  // 废钢库无人行车
  fg: {
    id: 11,
    title: '废钢库无人行车',
    content: [
      {
        title: '方案简介',
        textList: ['基于大视域高精度3D激光扫描系统，实现对整个料场实时扫描和3D建模，准确获取物料堆积情况和位置信息，确保行车自动抓放物料的精确度。还可根据系统设定的不同配比自动吸取物料，实现废钢料场的无人化、智能化。']
      },
      {
        title: '核心功能',
        textList: [
          '① 采用精准定位、三维扫描、电子防摇、智能AI检测等先进技术；',
          '② 保留原有驾驶室手动操作功能，可远程/本地模式切换行车操作模式；',
          '③ 新增地面远程手动功能，允许人工在地面使用手柄远程遥控行车；',
          '④ 新增远程全自动作业功能，行车根据系统物料配比，进行全自动作业；',
          '⑤ 具有多台行车联动作业功能，允许多台行车联动同时自动作业；',
          '⑥ 新增人员及车辆检测系统，实现来料车辆自动管理，车辆人员闯入检测，确保安全。',
        ]
      },
    ],
    apply: '',
    image:[
      require('../assets/detail/11-1.png'),
      require('../assets/detail/11-2.png'),
    ]
  },
  // 加热炉脱硫脱硝系统
  tltx: {
    id: 12,
    title: '加热炉脱硫脱硝系统',
    content: [
      {
        title: '方案简介',
        textList: ['为实现加热炉烟气排放达标，需要增加烟气脱硫脱硝系统。烟气治理采用固定床干法脱硫工艺+SCR脱硝工艺，将加热炉烟气由原烟道引入脱硫脱硝系统，净化后的烟气满足国家及地方政府环保指标要求，最终返回原烟道排放，且不影响加热炉正常工况。']
      },
      {
        title: '核心功能',
        textList: [
          '① 选择性催化还原法脱硝技术（SCR）；',
          '② 固定床干法脱硫技术。',
        ]
      },
      {
        title: '创新效果',
        textList: [
          '降低生产运行成本，提升加热炉烟气处理效率，加热炉烟气排放满足最新环保要求，满足现代化企业对生产环境的要求'
        ]
      }
    ],
    apply: '河钢邯钢',
    image:[
      require('../assets/detail/12-1.png'),
      require('../assets/detail/12-2.png'),
      require('../assets/detail/12-3.png'),
    ]
  },
  // 能源智联平台
  nyzl: {
    id: 13,
    title: '能源智联平台',
    content: [
      {
        title: '产品介绍',
        textList: ['能源智联平台提供建筑综合能源解决方案，设备运维、系统运行状态透明化展示；节能措施、碳节约实时可见；运营成本降低效果实时可见；具有极强的示范性作用，在社会层面产生促进节能的良好示范，助力国家碳达峰。',
          '基于项目用能特点及负荷分析，结合项目所在位置的各种可利用资源分析，量化筛选各类技术路线，以满足终端用户用能需求为根本出发点，运用“互联网+”智慧能源理念与模式，以合理利用高效分布式能源集成技术为手段，提升能源整体利用效率、降低能源成本。'
        ]
      },
      {
        title: '核心功能',
        textList: [
          '一网：数据智联中台',
          '实现数据的采集、存储、处理、下发功能，为各中心模块提供数据的管理、分析、控制服务。',
          '六中心',
          '① 运营展示中心：将实时能耗监测、定额管理、能源审计、合同能源管理项目、虚拟电厂及新能源部署项目指标进行统一的汇总展示，为整体运营管理提供服务。',
          '② 能碳管理中心：实现能源管理、定额管理、碳管理等功能。',
          '③ 优化决策中心：依据采集数据、上报数据，通过专业优化分析工具及知识图谱，形成能源结构、能耗费用、系统能效的优化改造方案，批量推动合同能源管理项目落地。',
          '④ 托管服务中心：对建筑能耗、设备运行、末端环境进行监测；建立设备管理体系；结合优化决策中心数据、实现能源设备集中运行、管理、维修、维护。',
          '⑤ 控制调度中心：结合实时运行数据、节能领域产业链图谱、行业专家经验，实现单体建筑的运行策略优化控制、群体建筑需求侧响应、虚拟电厂调度与控制。',
          '⑥ 交易支付中心：实现用户、能源公司、银行之间的能源费用、服务费用流动管理，服务合同能源管理业务、碳交易业务。',
        ]
      },
    ],
    apply: '北京亦庄园区、南京维景酒店、三亚柏悦酒店',
    image:[
      require('../assets/detail/13-1.png'),
      require('../assets/detail/13-2.png'),
      require('../assets/detail/13-3.png'),
    ]
  },
  //智慧泵站
  zhbz: {
    id: 14,
    title:'智慧泵站',
    content: [
      {
        title: '产品介绍',
        textList: ['瀚普智慧泵站是以高效设备、智能控制、智慧运维、智慧管控为基础，以提升循环水系统安全性、降低综合能耗、减少运维人员成本为目标的超一级能效泵站系统。']
      },
      {
        title: '核心功能',
        textList: [
          '全流程提效技术：从高效设备、低阻管网、工艺联动控制、运行优化控制等全方面进行提效，能够提升泵站综合效率15～40%。',
          '匹配度评价技术：基于匹配度评价技术，进行运行优化分析，实现运行优化控制，使设备处于高效运行状态。',
          '能效对标优化技术：按系统实际需求参数，依托瀚普能效对标系统，分析能效提升空间给出设备优化建议。',
        ]
      },
      {
        title: '产品应用',
        textList: [
          '智慧泵站是赋予了感知能力、决策能力、持续优化能力，同时具备安全、高效及控制的灵活性，以提高能效水平为目标的泵站系统。可广泛应用于各个行业循环水泵站或供水泵站建设、改造。',
        ]
      },
      {
        title: '创新效益',
        textList: [
          '智慧泵站通过数据采集、系统运行的精细化控制、末端联动控制等实现公辅系统与末端生产节奏、产品变化等实现信息互通，从而降低运行成本，通过智慧泵站大数据平台与末端生产产品产量、产能、质量等关键数据进行关联，从数据上支撑循环水系统运行与生产质量挂钩。',
          '通过全面提效，改造后节能率可达20~50%'
        ]
      },
    ],
    apply: '包钢股份、河钢宣钢',
    image:[
      require('../assets/detail/14-1.png'),
      require('../assets/detail/14-2.png'),
      require('../assets/detail/14-3.png'),
    ]
  },
  //大型风机极致能效技术
  dxfj: {
    id: 15,
    title:'大型风机极致能效技术',
    content: [
      {
        title: '产品介绍',
        textList: ['大型风机极致能效技术为钢企深度挖掘风机系统节能潜力，实现挖潜价值最大化，避免客户对风机节能反复式改造，深度挖潜是实现风机系统节能降耗达到30%的必行路径。所有的节能都以电表和实际效果作为验收的唯一依据，让节能真正的为钢企创造价值、解决问题。']
      },
      {
        title: '核心技术',
        textList: [
          '风机系统极致能效技术采用CFD流场分析、FEM有限元分析、三元控制等核心技术，通过CFD流场分析等工具，可以量化每一部分改造的投资回报比和对最终节能效果的贡献率，包括但不限于风机、电机、除尘器、管道、三元控制技术等节能手段。',
        ]
      },
      {
        title: '创新效益',
        textList: [
          '完成极致能效改造后节能率达到30%以上。'
        ]
      },
    ],
    apply: '新兴铸管、河钢宣钢',
    image:[
      require('../assets/detail/15-1.png'),
      require('../assets/detail/15-2.png'),
      require('../assets/detail/15-3.png'),
    ]
  }
}
export const jqr = {'jzx':dataJson['jzx'],'lzj':dataJson['lzj'],'zd':dataJson['zd'],'fg':dataJson['fg']}
export const gz = {'dzdc': dataJson['dzdc']}
export const lc = {'zhlc': dataJson['zhlc']}
export const bz = {'zhbz': dataJson['zhbz'],'dxfj':dataJson['dxfj']}
export const dt = {'tltx': dataJson['tltx'],'nyzl':dataJson['nyzl']}
export const example = {zhlc:dataJson['zhlc'],zhbz:dataJson['zhbz'],zfpc:dataJson['zfpc'],dzdc:dataJson['dzdc'],jzx:dataJson['jzx']}
export const lt = {'zfpc': dataJson['zfpc'],'ldsb':dataJson['ldsb'],'cwjs':dataJson['cwjs'],'qspg':dataJson['qspg'],'dxfj':dataJson['dxfj']}
export default dataJson