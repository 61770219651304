<template>
  <div style="height:80px">
    <!-- <header :class="fixed?'header':''" ref="header"> -->
    <header class="header">
      <div class="logos">
        <a href="http://www.emergen.cn/" target="_blank"><div class="h-logo1"></div></a>
        <div class="h-logo"  @click="goPage('/')"></div>
      </div>
      <div>
        <a :class="routerName===nameObj.home? 'to-link active': 'to-link'"  @click="goPage('/')">首页</a>
        <a-dropdown>
          <a :class="routerName.indexOf(nameObj.industry)!=-1?'to-link active ant-dropdown-link':'to-link ant-dropdown-link'" @click="e => e.preventDefault()">业务板块</a>
          <a-menu slot="overlay" @click="menuclick">
            <a-menu-item key="/industry"><a href="javascript:;">智慧机器人</a></a-menu-item>
            <a-menu-item key="/industrybz"><a href="javascript:;">极致能效</a></a-menu-item>
            <a-menu-item key="/industrydt"><a href="javascript:;">绿色低碳</a></a-menu-item>
            <a-menu-item key="/industrylc"><a href="javascript:;">智慧料场</a></a-menu-item>
            <a-menu-item key="/industrylt"><a href="javascript:;">智慧炼铁</a></a-menu-item>
            <a-menu-item key="/industryzg"><a href="javascript:;">智慧钢轧</a></a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- <a :class="routerName.indexOf(nameObj.benchmark)!=-1? 'to-link active': 'to-link'" @click="goPage('/benchmark')">标杆案例</a> -->
        <a :class="routerName===nameObj.about? 'to-link active': 'to-link'"  @click="goPage('/about')">走进瀚普</a>
        <a :class="routerName===nameObj.contactus? 'to-link active': 'to-link'"  @click="goPage('/contactus')">联系我们</a>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data () {
    return{
      fixed: false,
      offsetTop: 0,
      nameObj: {
        home: 'home',
        about: 'about',
        industry: 'industry',
        benchmark: 'benchmark',
        contactus: 'contactus',
      },
      routerName: 'home'
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll);
    // this.offsetTop = this.$refs.header.offsetHeight;
    this.getRouteName()
  },
  methods: {
    getRouteName () {
      this.routerName = this.$route.name
    },
    handleScroll() {
      this.fixed = window.scrollY >= this.offsetTop;
    },
    menuclick({ item, key, keyPath }) {
      this.$router.push(key).catch(() => true)
    },
    goPage(path) {
      this.$router.push(path).catch(() => true)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="less" scoped>
.header{
  width: 100%;
  background: #fff;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 1px #ccc;
}
header{
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  .logos{
    position: absolute;
    top: 14px;
    left: 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .h-logo1{
      cursor: pointer;
      width: 113px;
      height: 35px;
      background: url('../../assets/zclogo.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 50px;
      // top: 28px;
      // left: 19px;
    }
    .h-logo{
      cursor: pointer;
      width: 81px;
      height: 55px;
      background: url('../../assets/logo.png') no-repeat;
      background-size: 100% 100%;
      // top: 13px;
      // left: 180px;
    }
  }
  .to-link{
    margin-right: 52px;
    font-size: 16px;
    color: #181818;
    display: inline-block;
  }
  .active{
    color: #ec1c29;
    font-weight: bold;
    border-bottom: 4px solid #EC1C29;
    height: 80px;
  }
}
.ant-dropdown-menu-item{
  padding: 5px 18px;
  width: 130px;
  color: #181818;
}
.ant-dropdown-menu-item:hover{
  background: #EC1C29;
}
.ant-dropdown-menu-item > a{
  color: #181818;
}
.ant-dropdown-menu-item:hover.ant-dropdown-menu-item > a{
  color: #fff;
}
@media (max-width:1200px) {
  header{
    height: 80px;
    line-height: 100px;
    .logos{
      position: absolute;
      top: 10px;
      left: 19px;
      .h-logo1{
        width: 60px;
        height: 20px;
        margin-right: 10px;
      }
      .h-logo{
        width: 40px;
        height: 25px;
      }
    }
    .to-link{
      margin-right: 10px;
      font-size: 12px;
    }
    .active{
      height: 80px;
    }
  }
}
</style>