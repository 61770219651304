<template>
  <div class="programme">
    <div class="title">丰富的解决方案 聚焦核心场景</div>
    <a-row class="content" type="flex" justify="center">
      <a-col  :xl="6" :lg="12" :sm="12" :xs="12">
        <div class="content-item">
          <div class="bg"></div>
          <div class="c-title">智能化咨询</div>
          <div class="describe">
            为用户提供专业的智能化产品 数字化方案
          </div>
        </div>
      </a-col>
      <a-col :xl="6" :lg="12" :sm="12" :xs="12">
        <div class="content-item">
          <div class="bg"></div>
          <div class="c-title">极致能效</div>
          <div class="describe">
            建设超一级能效系统
          </div>
        </div>
      </a-col>
      <a-col :xl="6" :lg="12" :sm="12" :xs="12">
        <div class="content-item">
          <div class="bg"></div>
          <div class="c-title">智慧机器人</div>
          <div class="describe">
            ‌推动行业数字化转型、‌增强企业竞争力，是工业企业实现高质量发展的重要支撑
          </div>
        </div>
      </a-col>
      <a-col :xl="6" :lg="12" :sm="12" :xs="12">
        <div class="content-item">
          <div class="bg"></div>
          <div class="c-title">绿色低碳</div>
          <div class="describe">
            研发可落地的解决方案，在环保、降碳、节能等方向为行业赋能
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'programme',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.programme{
  padding-top: 100px;
  .title{
    font-weight: bold;
    font-size: 40px;
    color: #333;
    line-height: 60px;
    margin-bottom: 32px;
  }
  .content{
    width: 1200px;
    margin: 0 auto !important;
  }
  .content-item{
    width: 300px;
    height: 461px;
    background: rgba(248, 248, 248, .12);
    border: 4px solid #FFFFFF;
    margin-bottom: 12px;
    margin: 0 auto;
    .bg{
      margin: 0 auto;
      width: 132px;
      height: 124px;
      background: url('@/assets/home/cj1.png') no-repeat;
      background-size: 100% 100%;
    }
    .c-title{
      padding-top: 40px;
      padding-bottom: 46px;
      font-size: 24px;
      color: #000000;
      line-height: 36px;
    }
    .describe{
      margin: 0 auto;
      width: 224px;
    }
  }

}
@media (max-width:1200px) {
  .programme{
    padding-top: 20px;
    .title{
      font-weight: bold;
      font-size: 20px;
    }
    .content{
      width: 100%;
    }
    .content-item{
      width: 100%;
      height: 320px;
      margin: 0 auto;
      .bg{
        margin: 0 auto;
      }
      .c-title{
        padding-top: 15px;
        padding-bottom: 20px;
        font-size: 16px;
      }
      .describe{
        margin: 0 auto;
        width: auto;
        font-size: 12px;
      }
    }

  }
}
</style>