<template>
  <div class="home">
    <s-header></s-header>
    <banner></banner>
    <application></application>
    <programme></programme>
    <honor></honor>
    <example></example>
    <foot></foot>
  </div>
</template>

<script>
// @ is an alias to /src
import SHeader from './components/nav'
import Banner from './components/banner'
import Application from './components/application'
import Programme from './components/programme'
import Honor from './components/honor'
import Example from './components/example'
import Foot from './components/footer'
export default {
  name: 'HomeView',
  components: {
    SHeader,
    Banner,
    Application,
    Programme,
    Honor,
    Example,
    Foot
  }
}
</script>
<style lang="less" scoped>
.home{
  // width: 100%;
  text-align: center;
}
</style>
