<template>
  <div  class="honor">
    <div class="title">拥有专业的服务商资质</div>
    <a-row class="content" type="flex" justify="center">
      <a-col :xl="6" :lg="12" :sm="12" :xs="12" v-for="(i,idx) in list" :key="idx">
        <div class="item" :style="`background-image:url(${i})`"></div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const list = [
  require('../../assets/home/ry-1.png'),
  require('../../assets/home/ry-2.png'),
  require('../../assets/home/ry-3.png'),
  require('../../assets/home/ry-4.png'),
]
export default {
  data () {
    return{
      list
    }
  }
}
</script>

<style lang="less" scoped>
.honor{
  margin-top: 99px;
  height: 330px;
  // background: linear-gradient( 180deg, #FA9177 0%, #BA1319 100%);
  background: url('../../assets/home/ry.png') no-repeat;
  background-size: cover;
  .title{
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    padding: 30px 0;
  }
  .content{
    width: 1200px;
    margin: 0 auto;
    .item{
      width: 300px;
      height: 154px;
      background-size: cover;
    }
  }
}
@media (max-width:1200px) {
  .honor{
    width: 100%;
    margin-top: 49px;
    background-size: 100% 100%;
    .title{
      font-size: 20px;
      padding-bottom: 12px;
    }
    .content{
      width: 100%;
      .item{
        // width: 220px;
        width: 100%;
        height: 120px;
        background-size: 100% 100%;
      }
    }
  }
}
</style>