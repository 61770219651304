<template>
  <div class="application">
    <div class="title">优选的应用与行业服务</div>
    <div class="s-tab">
      <span v-for="(item,idx) in list" :key="idx" @click="handlClick(idx)" :class="currentIdx===idx?'tab-item active':'tab-item'">{{item.name}}</span>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
          <div class="swiper-slide slide1" @click="goPage('industry')">
            <img src="../../assets/home/hy-1.png" alt="">
            <div class="text">
              <div class="top">
                <span class="s-title">智慧机器人</span>
                <span class="word">业务方向</span>
              </div>
              <p>领跑智造，匠心掌控，基于工业互联网打造领先的智能制造</p>
              <div class="to-detail">了解更多</div>
            </div>
          </div>
          <div class="swiper-slide slide2" @click="goPage('industrybz')">
            <img src="../../assets/home/hy-2.png" alt="">
            <div class="text">
              <div class="top">
                <span class="s-title">极致能效</span>
                <span class="word">业务方向</span>
              </div>
              <p>建设超一级能效系统</p>
              <div class="to-detail">了解更多</div>
            </div>
          </div>
          <div class="swiper-slide slide3" @click="goPage('industrydt')">
            <img src="../../assets/home/hy-3.png" alt="">
            <div class="text">
              <div class="top">
                <span class="s-title">智慧低碳</span>
                <span class="word">业务方向</span>
              </div>
              <p>研发可落地的解决方案，在环保、降碳、节能等方向为行业赋能</p>
              <div class="to-detail">了解更多</div>
            </div>
          </div>
          <div class="swiper-slide slide4" @click="goPage('industrylc')">
            <img src="../../assets/home/hy-4.png" alt="">
            <div class="text">
              <div class="top">
                <span class="s-title">智慧料场</span>
                <span class="word">业务方向</span>
              </div>
              <p>料场的智能化建设已成为钢铁物流“降本增效、节能减排”的重要突破口</p>
              <div class="to-detail">了解更多</div>
            </div>
          </div>
          <div class="swiper-slide slide5" @click="goPage('industrylt')">
            <img src="../../assets/home/hy-5.png" alt="">
            <div class="text">
              <div class="top">
                <span class="s-title">智慧炼铁</span>
                <span class="word">业务方向</span>
              </div>
              <p>为炼铁降本注入新动力，提高生产效率、提升质量、保障安全生产</p>
              <div class="to-detail">了解更多</div>
            </div>
          </div>
          <div class="swiper-slide slide6" @click="goPage('industryzg')">
            <img src="../../assets/home/hy-6.png" alt="">
            <div class="text">
              <div class="top">
                <span class="s-title">智慧钢扎</span>
                <span class="word">业务方向</span>
              </div>
              <p>炼钢、轧钢工艺深度融合，提质、降本、增效为行业赋能</p>
              <div class="to-detail">了解更多</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
const list = [
  {name:'智慧机器人'},
  {name:'极致能效'},
  {name:'绿色低碳'},
  {name:'智慧料场'},
  {name:'智慧炼铁'},
  {name:'智慧钢轧'},
]
export default {
  name: 'application',
  data () {
    return {
      list,
      currentIdx: 0,
      mySwiper: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let that = this
      this.mySwiper = new Swiper ('.swiper-container', {
        loop: false, // 循环模式选项
        on: {
          slideChange: function(){
            that.currentIdx = this.activeIndex
          },
        }
      })
    },
    handlClick (index) {
      this.currentIdx = index
      this.mySwiper.slideTo(index)
    },
    goPage(path) {
      this.$router.push(path).catch(() => true)
    }
  }
}
</script>
<style lang="less" scoped>
@base-font-size: 16px;
// 转换函数
.px2rem(@ruleset; @px) {
  @rem: @px / @base-font-size * 1rem;
  @{ruleset}: @rem;
}
.application{
  padding-top: 100px;
  .title{
    font-weight: bold;
    font-size: 40px;
    color: #333;
    line-height: 60px;
    margin-bottom: 32px;
  }
  .s-tab{
    margin-bottom: 26px;
    .tab-item{
      margin: 0 50px;
      text-align: center;
      font-size: 18px;
      color: #666666;
      position: relative;
      padding-bottom: 8px;
      cursor: pointer;
    }
    .active{
      font-weight: bold;
      color: #000;
      border-bottom: 4px solid #EC1C29;
    }
  }
  .swiper-slide{
    img{
      width: 100%;
    };
    cursor: pointer;
    position: relative;
    .text{
      position: absolute;
      top: 20%;
      left: 8%;
      // top: 88px;
      // left: 120px;
      text-align: left;
      font-size: 16px;
      .top{
        margin-bottom: 10px;
        .s-title{
          font-size: 32px;
          font-weight: bold;
          padding-right: 15px;
          border-right: 1px solid #979797;
        }
        .word{
          padding-left: 17px;
          font-size: 14px;
          font-weight: bold;
          color: #4C4C4C;
          vertical-align: text-bottom;
        }
      }
      p{
          max-width: 576px;
      }
      .to-detail{
        margin-top: 63px;
        .px2rem(margin-top,58);
        width: 99px;
        height: 36px;
        border-radius: 18px;
        border: 1px solid #595959;
        font-weight: 500;
        font-size: 14px;
        color: #595959;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}
@media (max-width:1200px) {
  .application{
    padding-top: 20px;
    .title{
      font-size: 22px;
      margin-bottom: 12px;
    }
    .s-tab{
      display: none;
    }
    .swiper-slide{
      // img{
      //   height: 150px;
      // }
      .text{
        top: 10px;
        left: 12px;
        text-align: left;
        .top{
          margin-bottom: 2px;
          .s-title{
            font-size: 18px;
            font-weight: bold;
            padding-right: 15px;
            border-right: 1px solid #979797;
          }
          .word{
            padding-left: 17px;
            font-size: 12px;
            font-weight: bold;
            color: #4C4C4C;
            vertical-align: text-bottom;
          }
        }
        p{
          display: none;
            max-width: 576px;
            font-size: 14px;
        }
        .to-detail{
          display: none;
          margin-top: 20px;
          width: 80px;
          height: 24px;
          line-height: 24px;
          border-radius: 10px;
          border: 1px solid #595959;
          font-weight: 500;
          font-size: 12px;
        }
    }
    }
  }
}
</style>