<template>
  <div class="banner">
    <a-carousel autoplay :speed="1000" :autoplaySpeed="5000">
      <div class="item item3">
        <img src="../../assets/home/banner3.png" alt="">
      </div>
      <div class="item item1">
        <img src="../../assets/home/banner1.png" alt="">
        <div class="text">
          <p class="title">数据<span style="color:#000">驱动世界，</span>智慧<span style="color:#000">引领未来</span></p>
          <p class="info">智能制造系统解决方案供应商</p>
        </div>
      </div>
      <div class="item item2">
        <img src="../../assets/home/banner2.png" alt="">
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
// @base-font-size: 16px;
// // 转换函数
// .px2rem(@ruleset; @px) {
//   @rem: @px / @base-font-size * 1rem;
//   @{ruleset}: @rem;
// }
.banner{
  margin-top:3px;
  .item{
    img{
      width: 100%;
    }
  }
  .item1{
    position: relative;
    .text{
      position: absolute;
      // top: 220px;
      top: 35%;
      left: 18%;
      // left: 362px;
      .title{
        margin-bottom: 0;
        font-family: 'ruizi';
        font-size: 50px;
        font-weight: 400;
        color: #ec1c29;
        text-align: left;
        letter-spacing: 6px
      }
      .info{
        padding-top: 40px;
        // margin-left: 362px;
        font-size: 32px;
        color: rgba(0,0,0,0.85);
        text-align: left;
      }
    }
  }
}
@media (max-width:1200px) {
  .banner{
    margin-top:3px;
    .item{
    }
    .item1{
      .text{
        top: 30%;
        left: 14%;
        .title{
          font-size: 18px;
        }
        .info{
          padding-top: 5px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>