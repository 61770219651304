var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"application"},[_c('div',{staticClass:"title"},[_vm._v("优选的应用与行业服务")]),_c('div',{staticClass:"s-tab"},_vm._l((_vm.list),function(item,idx){return _c('span',{key:idx,class:_vm.currentIdx===idx?'tab-item active':'tab-item',on:{"click":function($event){return _vm.handlClick(idx)}}},[_vm._v(_vm._s(item.name))])}),0),_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide slide1",on:{"click":function($event){return _vm.goPage('industry')}}},[_c('img',{attrs:{"src":require("../../assets/home/hy-1.png"),"alt":""}}),_vm._m(0)]),_c('div',{staticClass:"swiper-slide slide2",on:{"click":function($event){return _vm.goPage('industrybz')}}},[_c('img',{attrs:{"src":require("../../assets/home/hy-2.png"),"alt":""}}),_vm._m(1)]),_c('div',{staticClass:"swiper-slide slide3",on:{"click":function($event){return _vm.goPage('industrydt')}}},[_c('img',{attrs:{"src":require("../../assets/home/hy-3.png"),"alt":""}}),_vm._m(2)]),_c('div',{staticClass:"swiper-slide slide4",on:{"click":function($event){return _vm.goPage('industrylc')}}},[_c('img',{attrs:{"src":require("../../assets/home/hy-4.png"),"alt":""}}),_vm._m(3)]),_c('div',{staticClass:"swiper-slide slide5",on:{"click":function($event){return _vm.goPage('industrylt')}}},[_c('img',{attrs:{"src":require("../../assets/home/hy-5.png"),"alt":""}}),_vm._m(4)]),_c('div',{staticClass:"swiper-slide slide6",on:{"click":function($event){return _vm.goPage('industryzg')}}},[_c('img',{attrs:{"src":require("../../assets/home/hy-6.png"),"alt":""}}),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"s-title"},[_vm._v("智慧机器人")]),_c('span',{staticClass:"word"},[_vm._v("业务方向")])]),_c('p',[_vm._v("领跑智造，匠心掌控，基于工业互联网打造领先的智能制造")]),_c('div',{staticClass:"to-detail"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"s-title"},[_vm._v("极致能效")]),_c('span',{staticClass:"word"},[_vm._v("业务方向")])]),_c('p',[_vm._v("建设超一级能效系统")]),_c('div',{staticClass:"to-detail"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"s-title"},[_vm._v("智慧低碳")]),_c('span',{staticClass:"word"},[_vm._v("业务方向")])]),_c('p',[_vm._v("研发可落地的解决方案，在环保、降碳、节能等方向为行业赋能")]),_c('div',{staticClass:"to-detail"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"s-title"},[_vm._v("智慧料场")]),_c('span',{staticClass:"word"},[_vm._v("业务方向")])]),_c('p',[_vm._v("料场的智能化建设已成为钢铁物流“降本增效、节能减排”的重要突破口")]),_c('div',{staticClass:"to-detail"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"s-title"},[_vm._v("智慧炼铁")]),_c('span',{staticClass:"word"},[_vm._v("业务方向")])]),_c('p',[_vm._v("为炼铁降本注入新动力，提高生产效率、提升质量、保障安全生产")]),_c('div',{staticClass:"to-detail"},[_vm._v("了解更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"s-title"},[_vm._v("智慧钢扎")]),_c('span',{staticClass:"word"},[_vm._v("业务方向")])]),_c('p',[_vm._v("炼钢、轧钢工艺深度融合，提质、降本、增效为行业赋能")]),_c('div',{staticClass:"to-detail"},[_vm._v("了解更多")])])
}]

export { render, staticRenderFns }